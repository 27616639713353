import { UserContext } from "../../Context/UserContext";
import React, { useContext, useState, useRef, useEffect } from "react";
import { scriptText } from "../../../services/componentText";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  SaveButton,
  ButtonContainer,
  DeleteButton,
  AddedNewButton,
  NavContainer,
  SelectScript,
  Option,
} from "./ScriptItem.styled";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../Main/Main";
import {
  fetchScriptTemplate,
  fetchScript,
  updateScript,
  createScript,
  deleteScript,
} from "../../../api/script";
import { useQuery } from "react-query";
import { fetchProduct } from "../../../api/product";
import css from "./ScriptItem.module.css";
import Select from "react-select";
import { v4 as uuidv4 } from 'uuid';

export const ScriptItem = () => {
  const { language } = useContext(UserContext);
  const [script, setScript] = useState({
    id: "",
    product_id: "",
    greetings_id: "",
    identyfying_needs_id: "",
    presentation_id: "",
    price_offer_id: "",
    crosstail_offer_id: "",
    handling_objections_id: "",
    close_deal: "",
    presentation_and_price_offer_text_ru: "",
    presentation_and_price_offer_text_ua: "",
  });

  const { data: scriptTemplateData } = useQuery(
    "scriptTemplateData",
    fetchScriptTemplate
  );

  const { data: productData } = useQuery("productData", fetchProduct);

  const [isPageRussian, setIsPageRussian] = useState(
    language === "russian" ? true : false
  );
  const location = useLocation();
  const navigate = useNavigate();

  const description_ru = useRef("");
  const description_ua = useRef("");
  const before_crossail_variation_ru = useRef("");
  const after_crossail_variation_ru = useRef("");
  const before_crossail_variation_ua = useRef("");
  const after_crossail_variation_ua = useRef("");
  const isCreateNew = location?.pathname === "/script/new";

  useEffect(() => {
    const findScript = async () => {
      const filteredData = scriptTemplateData?.data?.filter(
        (item) => Number(item.category_id) === 3
      );

      if (!filteredData || filteredData.length === 0) {
        return;
      }

      if (isCreateNew) {
        if (description_ru.current === "" && description_ua.current === "") {
          description_ru.current = filteredData[0]?.template_describe_ru || "";
          description_ua.current = filteredData[0]?.template_describe_ua || "";

          before_crossail_variation_ru.current =
            filteredData[0]?.before_crossail_variation_ru || "";
          after_crossail_variation_ru.current =
            filteredData[0]?.after_crossail_variation_ru || "";

          after_crossail_variation_ua.current =
            filteredData[0]?.after_crossail_variation_ua || "";
          before_crossail_variation_ua.current = filteredData[0]?.before_crossail_variation_ua || "";
        }
        return;
      }

      const idFromPath = location?.pathname?.replace(/^\/script\/change\//, "");

      const response = await fetchScript({ id: idFromPath });


        const fetchedTemplate = response?.data;

        const crossailVariation = JSON?.parse(
          fetchedTemplate[0]?.crossail_variation || "[]"
        );

        setScript({
          ...fetchedTemplate[0],
          crossail_variation: crossailVariation,
        });

        if (!description_ru.current) {
          description_ru.current =
            fetchedTemplate[0]?.presentation_and_price_offer_text_ru || "";
        }
        if (!description_ua.current) {
          description_ua.current =
            fetchedTemplate[0]?.presentation_and_price_offer_text_ua || "";
        }
      
    };

    findScript();
  }, [location, isCreateNew, scriptTemplateData?.data]);

  const handlePreSubmit = () => {
    const newScript = {
      id: script?.id || "",
      product_id: script?.product_id || "",
      greetings_id: script?.greetings_id || "",
      identyfying_needs_id: script?.identyfying_needs_id || "",
      presentation_id: script?.presentation_id || "",
      price_offer_id: script?.price_offer_id || "",
      crosstail_offer_id: script?.crosstail_offer_id || "",
      close_deal: script?.close_deal || "",
      handling_objections_id: script?.handling_objections_id || "",
      presentation_and_price_offer_text_ru: description_ru.current || "",
      presentation_and_price_offer_text_ua: description_ua.current || "",
    };

    handleSubmit(newScript);
  };

  const handleSubmit = async (formData) => {
    if (isCreateNew) {
      const response = await createScript(formData);

      if (response?.status === 201) {
        toast.success(scriptText[language][0]?.successSave);
        return navigate("/script/list");
      }
    }

    if (!isCreateNew) {
      const response = await updateScript(formData);

      if (response?.status === 201) {
        toast.success(scriptText[language][0]?.successUpdate);
        return navigate(`/script/list/${script?.id}`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/script/list");
  };

  const handleDelete = async (id) => {
    const response = await deleteScript(id);

    if (response?.status === 200) {
      toast.success(scriptText[language][0]?.successRemoved);
      return navigate("/script/list");
    }
  };

  const getProductTitle = (productId) => {
    const selectedProduct = productData?.data?.find(
      (product) => Number(product.id) === Number(productId)
    );
    return selectedProduct ? selectedProduct.title_ua : "";
  };

  const generateKey = () => {
    return uuidv4();
  };

  return (
    <Main>
      <NavContainer>
        <AddedNewButton
          onClick={() => setIsPageRussian(false)}
          active={!isPageRussian}
          disabled={!isPageRussian}
        >
          {scriptText[language][0]?.ukraineVersion}
        </AddedNewButton>
        <AddedNewButton
          onClick={() => setIsPageRussian(true)}
          active={isPageRussian}
          disabled={isPageRussian}
        >
          {scriptText[language][0]?.russianVersion}
        </AddedNewButton>
      </NavContainer>

      <Select
        value={
          script.product_id
            ? {
                label: getProductTitle(script.product_id),
                value: script.product_id,
              }
            : null
        }
        onChange={(selectedOption) => {
          const updateValue = {
            ...script,
            product_id: selectedOption.value,
          };
          setScript(updateValue);
        }}
        styles={{
          control: (provided) => ({ ...provided, width: "80%" }),
          menu: (provided) => ({ ...provided, zIndex: "3" }),
        }}
        options={[
          { label: "Выберите товар:", value: "0" },
          ...(productData?.data || []).map((title) => ({
            label: title.title_ua,
            value: title.id,
          })),
        ]}
        placeholder="Оберіть товар:"
      />

      <SelectScript
        value={script.greetings_id || ""}
        onChange={(e) => {
          const updateValue = {
            ...script,
            greetings_id: e.target.value,
          };
          setScript(updateValue);
        }}
        style={{ width: "80%", marginTop: "10px" }}
      >
        <Option key={generateKey()} value={"0"}>
          {language === "russian"
            ? "Выберите шаблон приветствия:"
            : "Обрати шаблон з блоку привітаня:"}
        </Option>
        {scriptTemplateData &&
          scriptTemplateData?.data?.map((title) => {
            if (Number(title.category_id) === 1) {
              return (
                <Option key={generateKey()} value={title.id}>
                  {title.template_title_ua}
                </Option>
              );
            } else {
              return null;
            }
          })}
      </SelectScript>

      {script?.greetings_id && (
        <>
          {scriptTemplateData?.data
            .filter(
              (template) => Number(template.id) === Number(script.greetings_id)
            )
            .map((selectedTemplate) => (
              <div
              key={generateKey()}
                className={css.scriptContent}
                dangerouslySetInnerHTML={{
                  __html: isPageRussian
                    ? selectedTemplate?.template_describe_ru
                    : selectedTemplate?.template_describe_ua,
                }}
              />
            ))}
        </>
      )}

      <SelectScript
        value={script.identyfying_needs_id || ""}
        onChange={(e) => {
          const updateValue = {
            ...script,
            identyfying_needs_id: e.target.value,
          };
          setScript(updateValue);
        }}
        style={{ width: "80%", marginTop: "10px" }}
      >
        <Option key={generateKey()} value={"0"}>
          {language === "russian"
            ? "Выберите шаблон Выявление потребностей:"
            : "Обрати шаблон з блоку Виявлення потреб:"}
        </Option>
        {scriptTemplateData &&
          scriptTemplateData?.data?.map((title) => {
            if (Number(title.category_id) === 2) {
              return (
                <Option key={generateKey()} value={title.id}>
                  {title.template_title_ua}
                </Option>
              );
            } else {
              return null;
            }
          })}
      </SelectScript>

      {script?.identyfying_needs_id && (
        <>
          {scriptTemplateData?.data
            .filter(
              (template) =>
                Number(template.id) === Number(script.identyfying_needs_id)
            )
            .map((selectedTemplate) => (
              <div
              key={generateKey()}
                className={css.scriptContent}
                dangerouslySetInnerHTML={{
                  __html: isPageRussian
                    ? selectedTemplate?.template_describe_ru
                    : selectedTemplate?.template_describe_ua,
                }}
                style={{ marginBottom: "10px" }}
              />
            ))}
        </>
      )}

      {isPageRussian && (
        <div>
          <div style={{ width: "100%", minHeight: "300px" }}>
            <MyEditor
              value={description_ru.current || ""}
              onChange={(newContent) => (description_ru.current = newContent)}
              className={css.newTox}
            />
          </div>
        </div>
      )}

      {!isPageRussian && (
        <div>
          <div style={{ width: "100%", minHeight: "300px" }}>
            <MyEditor
              value={description_ua.current || ""}
              onChange={(newContent) => (description_ua.current = newContent)}
            />
          </div>
        </div>
      )}

      <SelectScript
        value={script.crosstail_offer_id || ""}
        onChange={(e) => {
          const updateValue = {
            ...script,
            crosstail_offer_id: e.target.value,
          };
          setScript(updateValue);
        }}
        style={{ width: "80%", marginTop: "10px" }}
      >
        <Option key={generateKey()} value={"0"}>
          {language === "russian"
            ? "Выберите шаблон Предложение кроссейлов:"
            : "Обрати шаблон з блоку Пропозиція кросейлів:"}
        </Option>
        {scriptTemplateData &&
          scriptTemplateData?.data?.map((title) => {
            if (Number(title.category_id) === 5) {
              return (
                <Option key={generateKey()} value={title.id}>
                  {title.template_title_ua}
                </Option>
              );
            } else {
              return null;
            }
          })}
      </SelectScript>

      {script?.crosstail_offer_id && (
        <>
          {scriptTemplateData?.data
            .filter(
              (template) =>
                Number(template.id) === Number(script.crosstail_offer_id)
            )
            .map((selectedTemplate) => {
              const crossailVariation = JSON?.parse(
                selectedTemplate?.crossail_variation || "[]"
              );

              const names = productData?.data
                ?.filter((product) =>
                  crossailVariation?.some(
                    (variation) => Number(variation.id) === Number(product.id)
                  )
                )
                ?.map((product) => (
                  <div key={generateKey()}>{product.title_ua}</div>
                ));

              return (
                <div>
                  <div
                    key={generateKey()}
                    className={css.scriptContent}
                    dangerouslySetInnerHTML={{
                      __html: isPageRussian
                        ? selectedTemplate?.before_crossail_variation_ru
                        : selectedTemplate?.before_crossail_variation_ua,
                    }}
                    style={{marginBottom: '10px'}}
                  />
                  <div
                    key={generateKey()}
                    className={css.scriptContent}
                    style={{ marginBottom: "10px" }}
                  >
                    {names}
                  </div>
                  <div
                    key={generateKey()}
                    className={css.scriptContent}
                    dangerouslySetInnerHTML={{
                      __html: isPageRussian
                        ? selectedTemplate?.after_crossail_variation_ru
                        : selectedTemplate?.after_crossail_variation_ua,
                    }}
                    style={{marginBottom: '10px'}}
                  />
                </div>
              );
            })}
        </>
      )}

      <SelectScript
        value={script.close_deal || ""}
        onChange={(e) => {
          const updateValue = {
            ...script,
            close_deal: e.target.value,
          };
          setScript(updateValue);
        }}
        style={{ width: "80%", marginTop: "10px" }}
      >
        <Option key={generateKey()} value={"0"}>
          {language === "russian"
            ? "Выберите шаблон Завершения сделки:"
            : "Обрати шаблон з блоку  Завершення угоди:"}
        </Option>
        {scriptTemplateData &&
          scriptTemplateData?.data?.map((title) => {
            if (Number(title.category_id) === 7) {
              return (
                <Option key={generateKey()} value={title.id}>
                  {title.template_title_ua}
                </Option>
              );
            } else {
              return null;
            }
          })}
      </SelectScript>

      {script?.close_deal && (
        <>
          {scriptTemplateData?.data
            .filter(
              (template) => Number(template.id) === Number(script.close_deal)
            )
            .map((selectedTemplate) => (
              <div
              key={generateKey()}
                className={css.scriptContent}
                dangerouslySetInnerHTML={{
                  __html: isPageRussian
                    ? selectedTemplate?.template_describe_ru
                    : selectedTemplate?.template_describe_ua,
                }}
                style={{ marginBottom: "10px" }}
              />
            ))}
        </>
      )}

      <SelectScript
        value={script.handling_objections_id || ""}
        onChange={(e) => {
          const updateValue = {
            ...script,
            handling_objections_id: e.target.value,
          };
          setScript(updateValue);
        }}
        style={{ width: "80%", marginTop: "10px" }}
      >
        <Option key={generateKey()} value={"0"}>
          {language === "russian"
            ? "Выберите шаблон Отработки возаржений:"
            : "Обрати шаблон з блоку Відпрацюваня заперечень:"}
        </Option>
        {scriptTemplateData &&
          scriptTemplateData?.data?.map((title) => {
            if (Number(title.category_id) === 6) {
              return (
                <Option key={generateKey()} value={title.id}>
                  {title.template_title_ua}
                </Option>
              );
            } else {
              return null;
            }
          })}
      </SelectScript>

      {script?.handling_objections_id && (
        <>
          {scriptTemplateData?.data
            .filter(
              (template) =>
                Number(template.id) === Number(script.handling_objections_id)
            )
            .map((selectedTemplate) => (
              <div
              key={generateKey()}
                className={css.scriptContent}
                dangerouslySetInnerHTML={{
                  __html: isPageRussian
                    ? selectedTemplate?.template_describe_ru
                    : selectedTemplate?.template_describe_ua,
                }}
                style={{ marginBottom: "10px" }}
              />
            ))}
        </>
      )}

      {isCreateNew && (
        <ButtonContainer>
          <SaveButton onClick={() => handlePreSubmit()}>
            {scriptText[language][0]?.save}
          </SaveButton>
          <DeleteButton onClick={() => handleCancelClick()}>
            {scriptText[language][0]?.cancel}
          </DeleteButton>
        </ButtonContainer>
      )}

      {!isCreateNew && (
        <ButtonContainer>
          <SaveButton onClick={() => handlePreSubmit()}>
            {scriptText[language][0]?.update}
          </SaveButton>
          <DeleteButton
            onClick={() => {
              handleDelete(script.id);
            }}
          >
            {scriptText[language][0]?.delete}
          </DeleteButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
