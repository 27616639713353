import { useState, useEffect } from 'react';
import { Main } from '../../Main/Main';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { UserContext } from '../../Context/UserContext';
import 'react-toastify/dist/ReactToastify.css';

import {
  ProductListContainer,
  ProductItem,
  ProductLink,
  LinkContainer,
  IconButton,
  TrashButton,
  IconLinkContainer,
  SearchInput,
  SearchButton,
  SearchContainer,
} from './ProductsList.styled';
import { FaTrash, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { productsText } from '../../../services/componentText';
import { fetchProduct, deleteProduct } from '../../../api/product';
import { AiOutlineClear } from 'react-icons/ai';
import { Pagination } from '../../Pagination/Pagination';
import { PageLimitSelector } from '../../PageLimitSelector/PageLimitSelector';
import { LanguageSelector } from '../../LanguageSelector/LanguageSelector';

const access_level_e = Number(process.env.REACT_APP_E_LEVEL_ACCESS);
const access_level_f = Number(process.env.REACT_APP_F_LEVEL_ACCESS);
const access_level_g = Number(process.env.REACT_APP_G_LEVEL_ACCESS);

export const ProductList = () => {
  const { currentUser, language } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [isRussianTitle, setIsRussianTitle] = useState(language === 'russian' ? true : false);

  const accessLevel =
    currentUser?.access_level === access_level_g ||
    currentUser?.access_level === access_level_f ||
    currentUser?.access_level === access_level_e;

  const { data: productData, refetch } = useQuery('productData', fetchProduct);

  const handleDelete = async id => {
    const response = await deleteProduct(id);

    if (response?.status === 200) {
      toast.success(productsText[language][0]?.successRemoved);
      return refetch();
    }
  };

  const filteredProducts = productData?.data?.filter(item => {
    const titleUaMatch = item.title_ua
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const titleRuMatch = item.title_ru
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return titleUaMatch || titleRuMatch;
  });

  const handlePageLimitChange = newPageLimit => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = selectedPage => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (filteredProducts) {
      const totalItems = filteredProducts.length;
      const calculatedTotalPages = Math.ceil(totalItems / pageLimit);
      setTotalPages(calculatedTotalPages);

      if (currentPage > calculatedTotalPages) {
        setCurrentPage(1);
      }
    }
  }, [filteredProducts, pageLimit, currentPage]);

  const startIndex = (Number(currentPage) - 1) * Number(pageLimit);
  const endIndex = Number(startIndex) + Number(pageLimit);
  const paginatedProducts = filteredProducts?.slice(startIndex, endIndex);

  return (
    <div>
      <Main>
        {productData?.data?.length > 0 ? (
          <div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchContainer>
                  <SearchInput
                    type="text"
                    placeholder={productsText[language][0]?.searchTitle}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                  />
                  <SearchButton
                    type="button"
                    onClick={() => setSearchQuery('')}
                  >
                    <AiOutlineClear size={26} />
                  </SearchButton>
                </SearchContainer>
                <PageLimitSelector
                  pageLimit={pageLimit}
                  onChange={handlePageLimitChange}
                />

                <LanguageSelector
                  language={isRussianTitle}
                  onChange={value =>
                    setIsRussianTitle(value === 'true' ? true : false)
                  }
                />
              </div>

              <ProductListContainer style={{userSelect: 'none'}}>
                {paginatedProducts?.map(item => (
                  <ProductItem key={item.id}>
                    <LinkContainer accessLevel={accessLevel}>
                      <ProductLink to={`/products/product-list/${item.id}`}>
                        {isRussianTitle ? item.title_ru : item.title_ua}
                      </ProductLink>
                    </LinkContainer>
                    {accessLevel && (
                      <IconLinkContainer>
                        <IconButton to={`/products/change-product/${item.id}`}>
                          <FaEdit />
                        </IconButton>
                        <TrashButton onClick={() => handleDelete(item.id)}>
                          <FaTrash />
                        </TrashButton>
                      </IconLinkContainer>
                    )}
                  </ProductItem>
                ))}
              </ProductListContainer>

              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        ) : (
          <div>{productsText[language][0]?.notDataOrError}</div>
        )}
      </Main>
      <Outlet />
    </div>
  );
};
