import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Thead,
  TableContainer,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  Label,
  SaveButton,
  ButtonContainer,
  AddedNewAvailabilityButton,
  DeleteAvailabilityButton,
  AddedAvailabilityContainer,
  SearchContainer,
  SearchInput,
  SearchButton,
  TextAvailability,
  ModalContainer,
  InfoForm,
  Input,
  AddedFMSelect,
  AddedFMOption,
  ReportTextContainerForLabel,
  VariationsContainer,
} from "./ProductAvailabilityManager.styled";
import { UserContext } from "../../Context/UserContext";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { Modal } from "../../Modal/Modal";
import { AiOutlineSearch } from "react-icons/ai";
import { productAvailabilityText } from "../../../services/componentText";
import { fetchProduct, updateProduct } from "../../../api/product";
import { Formik } from "formik";
import Select from "react-select";
import { fetchProductStatus } from "../../../api/productAvailability";

export const ManagerProductAvailability = () => {
  const { language } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [initialValues, setInitialValues] = useState({
    id: "",
    product_availability: [],
    title_ua: "",
    title_ru: "",
    description_ua: "",
    description_ru: "",
    statuses_message: 1,
  });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddItem = (arrayName, defaultItem) => {
    setInitialValues({
      ...initialValues,
      [arrayName]: [...initialValues[arrayName], { ...defaultItem }],
    });
  };

  const handleRemoveLastItem = (arrayName) => {
    const updatedArray = [...initialValues[arrayName]];

    if (updatedArray.length > 0) {
      updatedArray.pop();
      setInitialValues({
        ...initialValues,
        [arrayName]: updatedArray,
      });
    }
  };

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...initialValues[arrayName]];
    updatedArray[index][field] = value;

    setInitialValues({
      ...initialValues,
      [arrayName]: updatedArray,
    });
  };

  const resetValue = () => {
    setInitialValues({
      id: "",
      product_availability: [],
      title_ua: "",
      title_ru: "",
      description_ua: "",
      description_ru: "",
    });
  };

  const handleUpdate = async () => {
    const response = await updateProduct(initialValues);

    if (response?.status === 201) {
      setIsModalOpen(false);
      resetValue();
      toast.success(productAvailabilityText[language][0]?.successUpdate);
     return  refetch()
    }
  };
  const { data: productData, refetch } = useQuery("productData", fetchProduct);

  const filteredProducts = productData?.data?.filter((item) => {
    if (item?.product_availability) {
      return item?.product_availability;
    }

    return ''
  });

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(inputValue);
  };

  const { data: productStatus } = useQuery("productStatus", fetchProductStatus);

  let maxListColumns = 0;
  productData?.data?.forEach((product) => {
    const productAvailability = JSON?.parse(
      product?.product_availability || "[]"
    );

    if (productAvailability?.length > maxListColumns) {
      maxListColumns = productAvailability?.length;
    }
  });

  const handleSelectChange = (selectedOption) => {
    const selectedProductId = selectedOption?.value;
    const selectedProduct = productData?.data?.find(
      (product) => product?.id === selectedProductId
    );

    if (selectedProduct) {
      const productAvailability = JSON?.parse(
        selectedProduct.product_availability || "[]"
      );

      setInitialValues({
        ...initialValues,
        id: selectedProductId,
        product_availability: productAvailability || [],
        title_ua: selectedProduct?.title_ua || "",
        title_ru: selectedProduct?.title_ru || "",
        description_ua: selectedProduct?.description_ua || "",
        description_ru: selectedProduct?.description_ru || "",
        statuses_message: 1,
      });
    }
  };

  const handleDecline = () => {
    setIsModalOpen(false);
    resetValue();
  };


  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <SearchInput
            type="text"
            placeholder={
              productAvailabilityText[language][0]?.enterProductTitle
            }
            value={inputValue}
            onChange={handleInputChange}
          />
          <SearchButton type="submit" onClick={(e) => handleSearch(e)}>
            <AiOutlineSearch size="24px" />
          </SearchButton>
        </SearchContainer>
        <AddedNewAvailabilityButton
          onClick={() => setIsModalOpen(true)}
          style={{ marginLeft: "30px" }}
        >
          {productAvailabilityText[language][0]?.addInformation}
        </AddedNewAvailabilityButton>
      </div>

      {isModalOpen && (
        <Modal closeModal={() => handleDecline()}>
          <ModalContainer>
            <Formik onSubmit={handleUpdate} initialValues={initialValues}>
              {({ handleSubmit, setFieldValue }) => (
                <InfoForm onSubmit={handleSubmit}>
                  <label htmlFor="selectedProduct">Оберіть товар:</label>
                  <Select
                    id="selectedProduct"
                    name="selectedProduct"
                    options={productData?.data?.map((product) => ({
                      label: product.title_ru,
                      value: product.id,
                    }))}
                    value={initialValues?.selectedProduct}
                    onChange={handleSelectChange}
                    placeholder="Оберіть товар:"
                  />

                  <VariationsContainer>
                    {initialValues?.product_availability?.map((item, index) => (
                      <AddedAvailabilityContainer key={index}>
                        <Label>
                          <ReportTextContainerForLabel>
                            {
                              productAvailabilityText[language][0]
                                ?.enterColorOrSize
                            }
                          </ReportTextContainerForLabel>
                          <Input
                            type="text"
                            name={`name_${index}`}
                            value={item.name}
                            onChange={(e) =>
                              handleArrayInputChange(
                                "product_availability",
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Label>

                        <Label>
                          <ReportTextContainerForLabel>
                            {
                              productAvailabilityText[language][0]
                                ?.chooseAvailability
                            }
                          </ReportTextContainerForLabel>

                          <AddedFMSelect
                            name={`status_${index}`}
                            value={item?.status}
                            onChange={(e) =>
                              handleArrayInputChange(
                                "product_availability",
                                index,
                                "status",
                                e.target.value
                              )
                            }
                          >
                            <AddedFMOption value="">
                              {
                                productAvailabilityText[language][0]
                                  ?.chooseAvailability
                              }
                            </AddedFMOption>
                            {productStatus?.map((status) => (
                              <AddedFMOption
                                key={status.product_status_id}
                                value={status.product_status_id}
                              >
                                {language === "ru"
                                  ? status?.product_status_title_RU
                                  : status?.product_status_title_UA}
                              </AddedFMOption>
                            ))}
                          </AddedFMSelect>
                        </Label>

                        <Label>
                          <ReportTextContainerForLabel>
                            {
                              productAvailabilityText[language][0]
                                ?.chooseDateWhenProductWillBeAvailable
                            }
                          </ReportTextContainerForLabel>
                          <Input
                            type="date"
                            name={`date_${index}`}
                            value={item.date}
                            onChange={(e) =>
                              handleArrayInputChange(
                                "product_availability",
                                index,
                                "date",
                                e.target.value
                              )
                            }
                          />
                        </Label>
                      </AddedAvailabilityContainer>
                    ))}
                    <ButtonContainer>
                      <SaveButton
                        type="button"
                        onClick={() =>
                          handleAddItem("product_availability", {
                            name: "",
                            status: "",
                            date: "",
                          })
                        }
                      >
                        {productAvailabilityText[language][0]?.addColor}
                      </SaveButton>
                      <DeleteAvailabilityButton
                        type="button"
                        onClick={() =>
                          handleRemoveLastItem("product_availability")
                        }
                      >
                        {productAvailabilityText[language][0]?.delete}
                      </DeleteAvailabilityButton>
                    </ButtonContainer>

                    <ButtonContainer style={{ marginTop: "10px" }}>
                      <SaveButton type="submit">
                        {productAvailabilityText[language][0]?.save}
                      </SaveButton>
                    </ButtonContainer>
                  </VariationsContainer>
                </InfoForm>
              )}
            </Formik>
          </ModalContainer>
        </Modal>
      )}

      <TableContainer style={{userSelect: 'none'}}>
        <Table>
          <Thead>
            <HeadTr>
              <Th>{productAvailabilityText[language][0]?.productTitle}</Th>
              {Array?.from({ length: maxListColumns })?.map((_, index) => (
                <Th key={index}>
                  {
                    productAvailabilityText[language][0]
                      ?.informationAboutVariation
                  }
                </Th>
              ))}
            </HeadTr>
          </Thead>
          <tbody>
            {productData &&
              filteredProducts?.map((product) => {
                const productAvailability = JSON?.parse(
                  product.product_availability || "[]"
                );

                if (productAvailability.length === 0) {
                  return null;
                }

                if (
                  !product.title_ru
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return null;
                }

                const missingCells =
                  maxListColumns - productAvailability?.length;

                return (
                  <Tr key={product.id}>
                    <Td>{product.title_ru}</Td>
                    {productAvailability?.map((variation) => {
                      const getStatusTitle = productStatus?.filter(
                        (item) =>
                          Number(item?.product_status_id) ===
                          Number(variation?.status)
                      );

                      const checkStatus =
                        Number(variation.status) === 3 ||
                        Number(variation.status) === 2;
                      return (
                        <React.Fragment key={variation.name}>
                          <Td>
                            <p>
                              {productAvailabilityText[language][0]?.colorSize}{" "}
                              {variation.name}
                            </p>
                            <TextAvailability checkStatus={checkStatus}>
                              {
                                productAvailabilityText[language][0]
                                  ?.availability
                              }{" "}
                              {language === "russian"
                                ? getStatusTitle?.[0]?.product_status_title_RU
                                : getStatusTitle?.[0]?.product_status_title_UA}
                            </TextAvailability>
                            {variation?.date && (
                              <p>
                                {
                                  productAvailabilityText[language][0]
                                    ?.dateWhenBeAvailable
                                }{" "}
                                {variation.date
                                  ? formattedDateToDayMonthYear(variation.date)
                                  : ""}
                              </p>
                            )}
                          </Td>
                        </React.Fragment>
                      );
                    })}
                    {Array?.from({ length: missingCells })?.map((_, index) => (
                      <Td key={`placeholder-${index}`} />
                    ))}
                  </Tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
