import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import {
  Thead,
  TableContainer,
  Table,
  Tr,
  HeadTr,
  Td,
  Th,
  SearchContainer,
  SearchInput,
  SearchButton,
  TextAvailability,
} from "./ProductAvailabilityUser.styled";
import { UserContext } from "../../Context/UserContext";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { AiOutlineSearch } from "react-icons/ai";
import { productAvailabilityText } from "../../../services/componentText";
import { fetchProduct } from "../../../api/product";
import { fetchProductStatus } from "../../../api/productAvailability";

export const ProductAvailabilityUser = () => {
  const { language } = useContext(UserContext);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const { data: productData } = useQuery("productData", fetchProduct);

  const filteredProducts = productData?.data?.filter((item) => {
    if (item?.product_availability) {
      return item?.product_availability;
    }

    return ''
  });

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchQuery(inputValue);
  };

  const { data: productStatus } = useQuery("productStatus", fetchProductStatus);

  let maxListColumns = 0;
  productData?.data?.forEach((product) => {
    const productAvailability = JSON?.parse(
      product.product_availability || "[]"
    );

    if (productAvailability?.length > maxListColumns) {
      maxListColumns = productAvailability?.length;
    }
  });

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <SearchInput
            type="text"
            placeholder={
              productAvailabilityText[language]?.[0]?.enterProductTitle
            }
            value={inputValue}
            onChange={handleInputChange}
          />
          <SearchButton type="submit" onClick={(e) => handleSearch(e)}>
            <AiOutlineSearch size="24px" />
          </SearchButton>
        </SearchContainer>
      </div>

      <TableContainer style={{ userSelect: "none" }}>
        <Table>
          <Thead>
            <HeadTr>
              <Th>{productAvailabilityText[language]?.[0]?.productTitle}</Th>
              {Array?.from({ length: maxListColumns })?.map((_, index) => (
                <Th key={index}>
                  {
                    productAvailabilityText[language]?.[0]
                      ?.informationAboutVariation
                  }
                </Th>
              ))}
            </HeadTr>
          </Thead>
          <tbody>
            {productData &&
              filteredProducts?.map((product) => {
                const productAvailability = JSON?.parse(
                  product.product_availability || "[]"
                );

                if (productAvailability.length === 0) {
                  return null;
                }

                if (
                  !product.title_ru
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                ) {
                  return null;
                }

                const missingCells =
                  maxListColumns - productAvailability?.length;

                return (
                  <Tr key={product.id}>
                    <Td>{product.title_ru}</Td>
                    {productAvailability?.map((variation) => {
                      const getStatusTitle = productStatus?.filter(
                        (item) =>
                          Number(item?.product_status_id) ===
                          Number(variation?.status)
                      );

                      const checkStatus =
                        Number(variation.status) === 3 ||
                        Number(variation.status) === 2;
                      return (
                        <React.Fragment key={variation.name}>
                          <Td>
                            <p>
                              {productAvailabilityText[language]?.[0]?.colorSize}{" "}
                              {variation.name}
                            </p>
                            <TextAvailability checkStatus={checkStatus}>
                              {
                                productAvailabilityText[language]?.[0]
                                  ?.availability
                              }{" "}
                              {language === "russian"
                                ? getStatusTitle?.[0]?.product_status_title_RU
                                : getStatusTitle?.[0]?.product_status_title_UA}
                            </TextAvailability>
                            {variation?.date && (
                              <p>
                                {
                                  productAvailabilityText[language]?.[0]
                                    ?.dateWhenBeAvailable
                                }{" "}
                                {variation.date
                                  ? formattedDateToDayMonthYear(variation.date)
                                  : ""}
                              </p>
                            )}
                          </Td>
                        </React.Fragment>
                      );
                    })}
                    {Array?.from({ length: missingCells })?.map((_, index) => (
                      <Td key={`placeholder-${index}`} />
                    ))}
                  </Tr>
                );
              })}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
